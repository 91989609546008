import { PricingCardType } from "../../utils/types/PricingCardType";

export const cardsData: PricingCardType[] = [
  {
    title: "MINI",
    price: "200 $",
    priceDetails: "/ 1 локация",
    features: [
      "Длительность – 30 минут",
      "Исходники – До 100 штук",
      "Ретушь – 4 фото",
      "Аренда фотостудии – Бесплатно",
      "Любой образ",
    ],
    additionalServices: {
      title: "Дополнительные услуги:",
      items: [
        "Помощь с позированием",
        "Визажист + 2000 tl",
        "Дополнительная ретушь фото – 1 шт. / 250 tl",
      ],
    },
    buttonText: "Забронировать съёмку",
  },
  {
    title: "STANDART",
    price: "500 $",
    priceDetails: "/ 2 локации",
    features: [
      "Длительность — 1 час 20 минут",
      "Исходники — До 200 штук",
      "Ретушь — 8 фото",
      "Аренда фотостудии — Бесплатно",
      "Любой образ",
    ],
    additionalServices: {
      title: "Дополнительные услуги:",
      items: [
        "Помощь с позированием",
        "Визажист + 2000 tl",
        "Дополнительная ретушь фото – 1 шт. / 250 tl",
      ],
    },
    buttonText: "Забронировать съёмку",
  },
  {
    title: "MAXI",
    price: "N tl",
    priceDetails: "/ Максимальное количество локаций",
    features: [
      "Длительность — 4 часа",
      "Исходники — До 500 штук",
      "Ретушь — 50 фото",
      "Аренда фотостудии — Бесплатно",
      "Визажист — Make-Up, Укладка",
      "Предварительный подбор образов — До 8 образов",
    ],
    additionalServices: {
      title: "Дополнительные услуги:",
      items: ["Дополнительная ретушь фото – 1 шт. / 250 tl"],
    },
    buttonText: "Забронировать съёмку",
  },
];
